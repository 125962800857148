import { useMemo } from 'react'
import { useConfig } from '../config'

export function useLoginRedirect() {
  const config = useConfig()

  const handleLoginRedirect = useMemo(() => {
    return () => {
      const signInUrl = `${config.cognitoEndpoint}/login?client_id=${config.userPoolClientId}&response_type=code&redirect_uri=${`${window.location.protocol}//${window.location.host}/login`}`
      window.location.href = signInUrl
    }
  }, [config])

  return { handleLoginRedirect }
}
